import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import { style } from '../../../../../style/style';
import DragDropFileDialog from '../../../../dialogs/DragDropFileDialog';
import CssCheckbox from '../components/CssCheckbox';
import {
  getAccessToken,
  WebServiceClient,
} from '../../../../../services/WebServiceClient';
import { LocalStorageService } from '../../../../../services/LocalStorageService';
import { downloadFile } from '../../../../../utils/DownloadFile';

const STEPS = Object.freeze({
  initial: 1,
  stepSelfUpdate: 2,
  selfUpdateUploadDoc: 3,
  letIntegrarUpdateDownload: 4,
  letIntegrarUpdateUpload: 5,
  letIntegrarUpdateUploadDialog: 6,
  proofOfAddressChangeUpload: 7,
});

export const PropertyAddressDoesNotMatch = props => {
  const [outputs, setOutputs] = useState({});
  const [openDialog, setOpenDialog] = useState(true);
  const [docID, setDocID] = useState('');
  const org = JSON.parse(LocalStorageService.get('organisation'));
  const [optionSelection, setOptionSelection] = useState({
    letIntegrarUpdate: false,
    correctAddress: false,
    selfUpdate: false,
  });

  const [step, setStep] = useState(STEPS.initial);
  const onChangeCheckbox = e => {
    const updatedQuestions = { ...optionSelection };

    updatedQuestions['letIntegrarUpdate'] = false;
    updatedQuestions['correctAddress'] = false;
    updatedQuestions['selfUpdate'] = false;

    updatedQuestions[e.target.name] = !!e.target.value;

    setOptionSelection(updatedQuestions);

    let newOutputs = { ...outputs };
    if (updatedQuestions['letIntegrarUpdate']) {
      newOutputs['PropertyAddressDoesntMatchOption'] = 2;
    } else if (updatedQuestions['correctAddress']) {
      newOutputs['PropertyAddressDoesntMatchOption'] = 1;
    } else if (updatedQuestions['selfUpdate']) {
      newOutputs['PropertyAddressDoesntMatchOption'] = 3;
    }
    setOutputs(newOutputs);
  };
  const onChange = e => {
    const updatedQuestions = { ...outputs };
    updatedQuestions[e.target.name] = e.target.value;

    setOutputs(updatedQuestions);
  };

  const renderStep = step => {
    switch (step) {
      case STEPS.initial:
        return (
          <Stack container>
            <p style={{ fontSize: '12px', margin: '10', whiteSpace: 'pre-line' }}>
              {props.milestone.bodyText}
            </p>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please select one of the following options you wish to take.
            </p>
            <CssCheckbox
              name="correctAddress"
              label="The address is correct. I will contact my current lender to update and resend the mortgage offer."
              checked={optionSelection['correctAddress']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <CssCheckbox
              name="letIntegrarUpdate"
              label="Let Integrar update land registry records on my behalf. I acknowledge I will be charged for this. I will fill in an authority form and upload this along with the relevant change of address document to the Portal."
              checked={optionSelection['letIntegrarUpdate']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <CssCheckbox
              name="selfUpdate"
              label="Update land registry myself and upload a digital copy of the correctly amended register when I receive this."
              checked={optionSelection['selfUpdate']}
              onClick={e => {
                onChangeCheckbox(e);
              }}
            />
            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                // Validate
                // Return run on submit function from parent
                // Return
                if (optionSelection.correctAddress) {
                  return props.submitFnc(props.index, outputs);
                }

                props.submitFnc(props.index, outputs, false, false);

                if (optionSelection.selfUpdate)
                  return setStep(STEPS.stepSelfUpdate);

                if (optionSelection.letIntegrarUpdate)
                  return setStep(STEPS.letIntegrarUpdateDownload);
              }}
              disabled={
                !optionSelection.correctAddress &&
                !optionSelection.letIntegrarUpdate &&
                !optionSelection.selfUpdate
              }
            >
              Submit
            </Button>
          </Stack>
        );
      case STEPS.stepSelfUpdate:
        return (
          <Stack container>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please now upload a copy of the updated title register as a PDF.
            </p>
            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                setStep(STEPS.selfUpdateUploadDoc);
              }}
            >
              Upload Document
            </Button>
          </Stack>
        );

      case STEPS.proofOfAddressChangeUpload:
        return (
          <Stack container>
            <p style={{ fontSize: '12px', marginTop: '10px' }}>
              Send a file to Integrar
            </p>
            <Dialog open={openDialog}>
              <DialogContent>
                <DragDropFileDialog
                  documentSelected={'ECPA'}
                  documentType="ECPA"
                  handleClose={cancelled => {
                    setOpenDialog(false);
                    if (!cancelled) {
                      return props.submitFnc(props.index, outputs);
                    } else {
                      setStep(STEPS.initial);
                      setOpenDialog(true);
                    }
                  }}
                  mortgage={props.mortgage}
                  setExpanded={() => {}}
                />
              </DialogContent>
            </Dialog>
            <Button
              variant="contained"
              style={{
                color: 'white',
                // height: '40px',
                // width: '100px',
                marginTop: '10px',
                marginRight: 'auto',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Upload Document
            </Button>
          </Stack>
        );
      case STEPS.letIntegrarUpdateUploadDialog:
        return (
          <Stack container>
            <p style={{ fontSize: '12px', marginTop: '10px' }}>
              Send a file to Integrar
            </p>
            <Dialog open={openDialog}>
              <DialogContent>
                <DragDropFileDialog
                  documentSelected={'LR'}
                  documentType="landregistry"
                  handleClose={cancelled => {
                    setOpenDialog(false);
                    if (!cancelled) {
                      setStep(STEPS.proofOfAddressChangeUpload);
                    } else {
                      setStep(STEPS.initial);
                      setOpenDialog(true);
                    }
                  }}
                  mortgage={props.mortgage}
                  setExpanded={() => {}}
                />
              </DialogContent>
            </Dialog>
          </Stack>
        );
      case STEPS.selfUpdateUploadDoc:
        return (
          <Stack container>
            <p style={{ fontSize: '12px', marginTop: '10px' }}>
              Send a file to Integrar
            </p>
            <Dialog open={openDialog}>
              <DialogContent>
                <DragDropFileDialog
                  documentSelected={'UTR'}
                  documentType="updatedtitleregister"
                  handleClose={cancelled => {
                    setOpenDialog(false);
                    if (!cancelled) {
                      return props.submitFnc(props.index, outputs);
                    } else {
                      setStep(STEPS.initial);
                      setOpenDialog(true);
                    }
                  }}
                  mortgage={props.mortgage}
                  setExpanded={() => {}}
                />
              </DialogContent>
            </Dialog>
          </Stack>
        );
      case STEPS.letIntegrarUpdateDownload:
        return (
          <Stack container>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please download and print the HM Land Registry Authority form,
              then sign and date it and scan it to your device as a PDF ready to
              upload.
            </p>
            <Button
              variant="contained"
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={async () => {
                const config = {
                  headers: { Authorization: getAccessToken() },
                };
                const params = {
                  orgId: org.id,
                  siteId: null,
                  documentName: props.milestone.toPortal[1].Option2[2].file,
                };
                let ret = await WebServiceClient.get(
                  `/supporting-document/${props.mortgage.Mortgage.id}`,
                  params,
                  config,
                );

                if (ret.status != 200) {
                  /// error
                } else {
                  setDocID(ret.data.id);
                  downloadFile(ret.data.fileUrl, props.milestone.file);
                  setStep(STEPS.letIntegrarUpdateUpload);
                }
              }}
            >
              Download Document
            </Button>
          </Stack>
        );

      case STEPS.letIntegrarUpdateUpload:
        return (
          <Stack container>
            <p style={{ fontSize: '12px', margin: '1' }}>
              Please now upload both the signed HM Land Registry Authority Form
              and a scanned PDF letter from Royal Mail Address Maintenance Unit.
              Please upload in the following order 1) HM Land Registry Authority
              Form, 2) Marriage certificate or other evidence.
            </p>
            <Button
              variant="contained"
              style={{
                color: 'white',
                margin: '10px',
              }}
              sx={style.darkGreenButton}
              onClick={() => {
                setStep(STEPS.letIntegrarUpdateUploadDialog);
              }}
            >
              Upload Document
            </Button>
          </Stack>
        );
    }
  };

  return (
    <Paper style={style.remortgage}>
      <div>
        <p style={{ fontSize: '16px', margin: '5' }}>
          Property address mismatch
        </p>
        <div style={{ display: 'flex' }}>
          <div key={props.index}>{renderStep(step)}</div>
        </div>
      </div>
    </Paper>
  );
};
